




















































import { Component, Vue } from 'vue-property-decorator';
import localforage from 'localforage';

import BtnDialog from '@/components/shared/BtnDialog.vue';
import CanvasCard from '@/components/shared/CanvasCard.vue';
import SendDebugDump from '@/components/settings/SendDebugDump.vue';
import LoadState from '@/components/settings/LoadState.vue';
import SyncDataOptions from '@/components/settings/SyncDataOptions.vue';

@Component({
  components: {
    BtnDialog,
    CanvasCard,
    SendDebugDump,
    LoadState,
    SyncDataOptions,
  },
})
export default class SettingsContent extends Vue {

}
