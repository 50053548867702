

























import { Component, Vue } from 'vue-property-decorator';
import SLayoutDefault from '@stratumfive/ui-baseplate/src/components/SLayoutDefault/SLayoutDefault.vue';

import CurrentUserSettings from '@/components/settings/CurrentUserSettings.vue';
import ApplicationMetaData from '@/components/settings/ApplicationMetaData.vue';
import ClearStorage from '@/components/settings/ClearStorage.vue';
import PageContentWrapper from '@/components/shared/PageContentWrapper.vue';

@Component({
  components: {
    CurrentUserSettings,
    ApplicationMetaData,
    ClearStorage,
    PageContentWrapper,
    SLayoutDefault,
  },
})
export default class SettingsContent extends Vue {}
