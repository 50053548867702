





















import Vue from 'vue';
import SummaryItem from '@/components/shared/SummaryItem.vue';
import CanvasCard from '@/components/shared/CanvasCard.vue';

export default Vue.extend({
  components: {
    SummaryItem,
    CanvasCard,
  },
  data() {
    return {
      hash: process.env.VUE_APP_HASH,
      branch: process.env.VUE_APP_BRANCH,
    };
  },
  computed: {
    version() {
      return this.$store.state.systemCompatibility.version;
    },
  },
  created() {
    this.$store.commit('systemCompatibility/setVersion', process.env.VUE_APP_VERSION);
  },
  methods: {
    loadNewVersion() {
      window.caches
        .keys()
        .then((cacheNames) => Promise.all(
          cacheNames.map((cacheName) => window.caches.delete(cacheName)),
        ))
        .then(() => {
          if (navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations().then(
              (registrations) => {
                for (const registration of registrations) {
                  registration.unregister();
                }
                window.location.reload(true);
              },
            );
          } else {
            window.location.reload(true);
          }
        });
    },
  },
});
