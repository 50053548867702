



















































import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

export default Vue.extend({
  name: 'SyncDataOptions',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      count: 0,
      errors: false,
      loading: false,
      success: false,
    };
  },
  computed: {
    ...mapState('dataOptions', ['options']),
  },
  methods: {
    async download() {
      this.loading = true;
      this.errors = false;
      this.success = false;

      try {
        await this.loadDataOptions({ force: true });
        this.count = this.options.length;
        this.success = true;
      } catch (e) {
        console.error(e);
        this.errors = true;
      }

      this.loading = false;
    },
    ...mapActions('dataOptions', ['loadDataOptions']),
  },
});
