













































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import SummaryItem from '@/components/shared/SummaryItem.vue';
import BtnDialog from '@/components/shared/BtnDialog.vue';
import CanvasCard from '@/components/shared/CanvasCard.vue';
import SInputText from '@stratumfive/ui-baseplate/src/components/SInputText/SInputText.vue';
import SButton from '@stratumfive/ui-baseplate/src/components/SButton/SButton.vue';
import { removeDatabase, init } from '@/rxdb/Database.service';

const SUGGESTED_MAX_LOW_BANDWIDTH_DAYS_TO_SYNC = 14;

@Component({
  components: {
    CanvasCard,
    SummaryItem,
    BtnDialog,
    SInputText,
    SButton,
  },
})
export default class SettingsContent extends Vue {
  @State((state) => state.user.authorName) private authorName;

  @State((state) => state.ui.syncDuration) private syncDuration;

  @Getter('getUser') private getUser;

  @Action('logout') private logout;

  private valid = false;

  private syncDurationValid = false;

  private showUpdateDurationDialog = false;

  private newAuthorName: string;

  private localSyncDuration: number;

  private updatingSyncDuration = false;

  constructor() {
    super();
    this.newAuthorName = '';
    this.localSyncDuration = 10;
  }

  private logoutApp() {
    this.logout();
    this.$router.push({ name: 'login' });
  }

  private required(value): any {
    return !!value || 'Required.';
  }

  private min2(value): any {
    return Number(value) >= 2 || 'Value must be 2 or larger';
  }

  private max2Years(value): any {
    return Number(value) <= 720 || 'Value must be less than 720 days';
  }

  @Watch('authorName', {
    immediate: true,
  })
  privateWatchAuthorName() {
    this.newAuthorName = this.authorName;
  }

  @Watch('syncDuration', {
    immediate: true,
  })
  privateWatchASyncDuration() {
    this.localSyncDuration = this.syncDuration;
  }

  private setAuthorName() {
    this.$store.commit('setAuthorName', { authorName: this.newAuthorName });
  }

  private async onUpdateDaysToSynchroniseClicked() {
    if (this.localSyncDuration <= SUGGESTED_MAX_LOW_BANDWIDTH_DAYS_TO_SYNC) {
      await this.updateSyncDuration();
    } else {
      this.showUpdateDurationDialog = true;
    }
  }

  private async updateSyncDuration() {
    this.updatingSyncDuration = true;
    this.$store.commit('ui/setSyncDuration', { duration: this.localSyncDuration });
    await removeDatabase();
    const authToken = this.$store.state.user.token;
    await init(authToken, this.localSyncDuration);
    this.updatingSyncDuration = false;
    this.showUpdateDurationDialog = false;
  }
}
