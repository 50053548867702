















































import { Component, Vue, Prop } from 'vue-property-decorator';
import JSZip from 'jszip';
import SAlert from '@stratumfive/ui-baseplate/src/components/SAlert/SAlert.vue';
import { API } from '@/API';
import CanvasCard from '@/components/shared/CanvasCard.vue';
import { getDatabase } from '@/rxdb/Database.service';

@Component({
  components: {
    CanvasCard,
  },
})
export default class SendDebugDump extends Vue {
  @Prop({ required: true }) open: boolean;

  private loading = false;

  private errors = false;

  private success = false;

  /**
   * Send the state and browser info to us to help debug issues
   */
  private async sendDebugDump() {
    this.loading = true;
    this.errors = false;
    this.success = false;

    try {
      // Create a new zip file to hold the debug info
      const zip = new JSZip();

      // Copies the window.navigator object into our own object
      // eslint-disable-next-line no-inner-declarations
      function recur(obj) {
        const result = {};
        // eslint-disable-next-line no-underscore-dangle
        let _tmp;
        // eslint-disable-next-line no-restricted-syntax
        for (const i in obj) {
          // enabledPlugin is too nested, also skip functions
          if (i === 'enabledPlugin' || typeof obj[i] === 'function') {
            // eslint-disable-next-line no-continue
            continue;
          } else if (typeof obj[i] === 'object') {
            // get props recursively
            _tmp = recur(obj[i]);
            // if object is not {}
            if (Object.keys(_tmp).length) {
              result[i] = _tmp;
            }
          } else {
            // string, number or boolean
            result[i] = obj[i];
          }
        }
        return result;
      }
      const browserInfo: any = recur(window.navigator);
      const storageInfo = await window.navigator.storage.estimate();
      browserInfo.storageInfo = storageInfo;
      zip.file('browser.json', JSON.stringify(browserInfo));
      zip.file('state.json', JSON.stringify(this.$store.state));

      const db = await getDatabase();
      const rxdbDump = await db.dump();

      zip.file('rxdb.json', JSON.stringify(rxdbDump));
      const zipFile = await zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
          level: 9,
        },
      });

      const data: any = new FormData();
      data.append('file', zipFile, 'debug.zip');
      const res = await API.post('/debug', data, {
        headers: {
          // eslint-disable-next-line no-underscore-dangle
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      });

      this.success = true;
    } catch (e) {
      console.error(e);
      this.errors = true;
    }

    this.loading = false;
  }
}
