




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import HelpText from '@/components/shared/HelpText.vue';

@Component({
  components: {
    HelpText,
  },
})
export default class SummaryItem extends Vue {
  @Prop({ required: true })
  private item: object;

  @Prop() private description: string;

  @Prop() private suffix: string;

  @Prop() private keyColor: string;

  @Prop() private warning: boolean;

  private get cubicMetres() {
    return this.suffix === 'cubicMetres';
  }
}
