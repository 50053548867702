















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CardTitle from '@/components/shared/CardTitle.vue';

@Component({
  components: {
    CardTitle,
  },
})
export default class BtnDialog extends Vue {
  @Prop({ required: true })
  private buttonLabel: string;

  @Prop({ required: true })
  private confirmAction: () => void;

  @Prop({ required: true })
  private message: string;

  @Prop() private fullwidth: boolean;

  @Prop() private icon: string;

  @Prop() private block: boolean;

  private dialog: boolean;

  constructor() {
    super();
    this.dialog = false;
  }

  private toggleDialog() {
    this.dialog = !this.dialog;
  }

  private confirm() {
    this.confirmAction();
    this.dialog = !this.dialog;
  }

  private close() {
    this.confirmAction();
  }
}
