




import { Component, Vue } from 'vue-property-decorator';
import SettingsContent from '@/components/settings/SettingsContent.vue';

@Component({
  components: {
    SettingsContent,
  },
  metaInfo() {
    return {
      title: 'Settings',
    };
  },
})
export default class Settings extends Vue {}
