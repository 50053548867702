

































import { Component, Vue, Prop } from 'vue-property-decorator';
import JSZip from 'jszip';
import SAlert from '@stratumfive/ui-baseplate/src/components/SAlert/SAlert.vue';
import SButton from '@stratumfive/ui-baseplate/src/components/SButton/SButton.vue';
import { API } from '@/API';
import CanvasCard from '@/components/shared/CanvasCard.vue';

@Component({
  components: {
    CanvasCard,
    SButton,
  },
})
export default class LoadState extends Vue {
  @Prop({ required: true }) open: boolean;

  private loading = false;

  private errors = false;

  private success = false;

  private load(): any {
    const { input } = this.$refs;
    const fileInput: any = input;
    const file = fileInput.files[0];
    const fr = new FileReader();
    fr.onload = (e: any) => {
      const lines = e.target.result;
      const state = JSON.parse(lines);
      Object.keys(state).forEach((key) => {
        this.$store.state[key] = state[key];
      });
    };
    fr.readAsText(file);
  }
}
